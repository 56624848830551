import { Component } from '@angular/core';

// import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { BleClient } from '@capacitor-community/bluetooth-le';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {
    
  }
  // constructor(private ble: BLE) {
  //   ble.scan([], 10).subscribe((data) => {
  //     console.log(data);
  //   });
  //  }

  async scan(): Promise<void> {
    try {
      await BleClient.initialize();

      const HEART_RATE_SERVICE = '4fafc201-1fb5-459e-8fcc-c5c9c3319143';
  
      const device = await BleClient.requestDevice({
        services: [HEART_RATE_SERVICE]
      });
  
      // connect to device, the onDisconnect callback is optional
      await BleClient.connect(device.deviceId, (deviceId) => this.onDisconnect(deviceId));
      console.log('connected to device', device);



      

    } catch (error) {
      console.error(error);
    }
  }

  onDisconnect(deviceId: string): void {
    console.log(`device ${deviceId} disconnected`);
  }
}
