
export enum WheightVisibility {
    GRAM,
    GRAIN,
    BOTH
  }
  
  export enum SpineVisibility {
    AMO,
    ASTM,
    BOTH
  }
  