
export class Spine{
    amo: number;
    astm: number;
}

export class Weight{
    gram: number;
    grain: number;
}

export class SpineMeasurement{
    storeNumber?: number;
    arrowWeight?: Weight;
    combinedSpine?: Spine;
    firstSpine?: Spine;
    secondSpine?: Spine;
    straightness?: number;
}