import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ConnectionState, ConnectionType, Device, DeviceApi } from "../model/device.model";
import { SpineMeasurement } from "../model/spine.model";
import { BleConnectionService } from "./ble-connection.service";
import { SpineMeasurementService } from "./spine-measurement.service";

@Injectable({
  providedIn: 'root',
})
export class DeviceService implements OnDestroy {
  
  private subscriptions = new Subscription();

  private activeDeviceSubscriptions = new Subscription();

  public connectionState$ = new BehaviorSubject<ConnectionState>(ConnectionState.DISCONNECTED);
  public device$ = new BehaviorSubject<Device>(new Device());

  private device = new Device();
  private spineState = new SpineMeasurement();

  // private deviceApis: DeviceApi[] = [];

  private activeDeviceApi: DeviceApi;

  // private bleConnectionState: BleConnectionState;

  constructor(
    private bleConnectionService: BleConnectionService, 
    private spineMeasurementService: SpineMeasurementService,
    private zone: NgZone) {

    // this.subscriptions.add(bleConnectionService.connectionState$.subscribe((state) => {
    //   this.bleConnectionState = state;

    //   if(this.bleConnectionState.bleState === BleState.CONNECTED
    //       && this.device.connected == false) {
    //         this.onConnect();
    //   } else if(this.bleConnectionState.bleState !== BleState.CONNECTED
    //     && this.device.connected == true) {
    //       this.onDisconnect();
    //   }
    
    // })
    // );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setActiveDeviceApi(): void {
    if(this.bleConnectionService.available) {
      this.activeDeviceApi = this.bleConnectionService;
    }
  }

  // registerDeviceApi(deviceApi: DeviceApi) {
  //   this.deviceApis.push(deviceApi);
  // }

  async connect(): Promise<boolean> {
    if(this.activeDeviceApi) {
      await this.activeDeviceApi.disconnect();
    }
    this.setActiveDeviceApi();
    if(this.activeDeviceApi) {
      this.activeDeviceSubscriptions.add(this.activeDeviceApi.connectionState$.subscribe((state) => {
        console.log('connectionStateChanged', state);
        this.connectionStateChanged(state);
      }));
      this.activeDeviceSubscriptions.add(this.activeDeviceApi.dataReceived$.subscribe((data) => {
        this.dataReceived(data);
      }));
      return await this.activeDeviceApi.connect();
    }
    return false;
  }

  async disconnect(): Promise<void> {
    if(this.activeDeviceApi) {
      return await this.activeDeviceApi.disconnect();
    }
    this.activeDeviceSubscriptions.unsubscribe();
  }

  private connectionStateChanged(connectionState: ConnectionState) {
    this.zone.run(() => {
      switch (connectionState) {
        case ConnectionState.CONNECTING:
          this.onConnecting();
          break;
        case ConnectionState.CONNECTED:
          this.onConnected();
          break;
        case ConnectionState.DISCONNECTING:
          this.onDisconnecting();
          break;
        case ConnectionState.DISCONNECTED:
          this.activeDeviceApi = undefined;
          this.onDisconnected();
          break;
        default:
          break;
      }
      this.connectionState$.next(connectionState);
    });
  }

  // public connect(connectionType?: ConnectionType) {

  // }

  // public disconnect() {

  // }

  private onConnecting(): void {
    this.device = new Device();
    this.device.connectionState = ConnectionState.CONNECTING;
    this.device.connectionType = this.activeDeviceApi.connectionType;
    this.spineState = new SpineMeasurement();
    this.device$.next(this.device);
  }

  private onConnected(): void {
    this.device = new Device();
    this.device.connectionState = ConnectionState.CONNECTED;
    this.device.connectionType = this.activeDeviceApi.connectionType;
    this.spineState = new SpineMeasurement();
    this.device.name = this.activeDeviceApi.deviceName || 'Arrow Analyzer';
    this.device$.next(this.device);
  }

  private onDisconnecting(): void {
    this.device.connectionState = ConnectionState.DISCONNECTING;
    this.device.connectionType = this.activeDeviceApi.connectionType;
    this.spineState = new SpineMeasurement();
    this.device$.next(this.device);
  }

  private onDisconnected(): void {
    this.device = new Device();
    this.spineState = new SpineMeasurement();
    this.device$.next(this.device);
  }

  
  private dataReceived(receivedData: string) {
    this.zone.run(() => {
      const values = receivedData.split('|');

      if(receivedData.startsWith('at|')) {
          this.spineState = new SpineMeasurement();
          this.spineMeasurementService.arrowTaken();
          // this.spineMeasurementService.newMeasurement(this.spineState)
      } else if(receivedData.startsWith('aw|')) {
          this.spineState.arrowWeight = {gram: parseFloat(values[1]), grain: parseInt(values[2])};
          this.spineMeasurementService.newMeasurement(this.spineState)
      } else if(receivedData.startsWith('as1|')) {
          this.spineState.firstSpine = {amo: parseFloat(values[1]), astm: parseInt(values[2])};
          this.spineState.combinedSpine = this.spineState.firstSpine;
          this.spineMeasurementService.newMeasurement(this.spineState)
      } else if(receivedData.startsWith('as2|')) {
          this.spineState.secondSpine = {amo: parseFloat(values[1]), astm: parseInt(values[2])};
          this.spineState.straightness = parseFloat(values[3]);
          this.spineState.combinedSpine = {amo: parseFloat(values[4]), astm: parseInt(values[5])};
          this.spineMeasurementService.newMeasurement(this.spineState)
      } else if(receivedData.startsWith('OKgsp|')) {
          this.device.firmwareVersion = values[1];
          this.device.firmwareBuild = parseInt(values[2]);
          this.device.hardwareVersion = values[3];
          this.device.hardwareBuild = parseInt(values[4]);
          this.device.serialNumber = values[5];
          this.device.bootloaderVersion = values[6];
          this.device.bootloaderBuild = parseInt(values[7]);
          this.device.customizeId = values[8];
          this.device$.next(this.device);
      }
    });
  }


  nextArrow = 1;
  simulateArrow() {
    const arrows: [string[]]= [[]];
    arrows.push(['aw|54.7|844|', 'as1|16.2|1943|111.35|', 'as2|16.2|1871|0.007|16.5|1907|15.7|', 'at|'])
    arrows.push(['aw|50.3|804|', 'as1|13.2|1543|111.35|', 'as2|13.2|1571|0.007|13.5|1507|11.7|', 'at|'])
    arrows.push(['aw|74.6|804|', 'as1|113.2|543|111.35|', 'as2|113.2|571|0.007|113.5|507|11.7|', 'at|'])
    // arrows.push(['aw|66.8|950|', 'as1|35.1|956|256.45|', 'at|'])
    arrows[this.nextArrow].forEach((item) => {this.dataReceived(item)});
    this.nextArrow++;
    if(this.nextArrow >= arrows.length) {
      this.nextArrow = 1;
    }
    // this.parseReceivedData('aw|54.7|844|');
    // this.parseReceivedData('as1|16.2|1943|111.35|');
    // this.parseReceivedData('as2|16.2|1871|0.007|16.5|1907|15.7|');
    // this.parseReceivedData('at|');
  }
}