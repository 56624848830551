import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { SpineVisibility, WheightVisibility } from "../model/spine-view.model";
import { TargetSettings } from "../model/target-settings.model";





@Injectable({
    providedIn: 'root',
  })
export class ConfigurationService {

  // weightUnits = WheightVisibility.BOTH;
  // spineUnits = SpineVisibility.BOTH;

  public weightUnits$ = new BehaviorSubject<WheightVisibility>(WheightVisibility.BOTH);
  public spineUnits$ = new BehaviorSubject<SpineVisibility>(SpineVisibility.BOTH);
  public autoAddMeasurementToList$  = new BehaviorSubject<boolean>(false);
  public targetSettings$ = new BehaviorSubject<TargetSettings>(new TargetSettings());
  // public compactSpineLiveView$  = new BehaviorSubject<boolean>(false);

  constructor() {}
  
  // public setCompactSpineLiveView(compact: boolean) {
  //   this.compactSpineLiveView$.next(compact);
  // }

  public setSpineUnit(spineUnit: SpineVisibility) {
    this.spineUnits$.next(spineUnit);
  }

  public setWheightUnit(weightUnit: WheightVisibility) {
    this.weightUnits$.next(weightUnit);
  }

  public setAutoAddMeasurementToList(auto: boolean) {
    this.autoAddMeasurementToList$.next(auto);
  }

  public setTargetSettings(settings: TargetSettings) {
    this.targetSettings$.next(settings);
  }
}